import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../App.css";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { uiAction, spotsAction } from "../store/store-actions";
import Map from "../components/Map";
import ImageSlider from "../components/ImageSlider";
import AutoComplete from "../components/AutoComplete";
import FeaturedSpots from "../components/FeaturedSpotsSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowDown,
  faPlane,
  faList,
  faHeart,
  // faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import PopularCities from "../components/PopularCities";
import StadiumParkings from "../components/StadiumParkings";
import AirportParkings from "../components/AirportParkings";
import { useGetHttp } from "../hooks/use-http";

const Home = () => {
  const dispatch = useDispatch();
  const sendGetRequest = useGetHttp();
  const { curLocGeoCords, featuredSpots } = useSelector((state) => {
    return {
      curLocGeoCords: state.uiStates.curLocGeoCords.lat_lng,
      featuredSpots: state.parking.featuredSpots,
    };
  });

  const setFeaturedSpots = (data) => {
    dispatch(spotsAction.setFeaturedSpots(data));
  };
  
  useEffect(() => {
    //loading featured spots
    sendGetRequest({ url: "getFeaturedSpots.json" }, setFeaturedSpots);

    if (curLocGeoCords === null || curLocGeoCords === "") {
      //prompt user current location
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          uiAction.setCurLocGeoCords(
            `${position.coords.latitude},${position.coords.longitude}`
          )
        );
      });
    }
  }, [dispatch]);

  return (
    <Fragment>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div id="content" className="flex flex-col flex-1">
          <main id="main">
            {curLocGeoCords !== "" && (
              <div id="banner-map" className="relative">
                <div
                  className="flex flex-1 relative"
                  style={{ minHeight: "680px" }}
                >
                  <div
                    id="map"
                    className="flex-1 relative"
                    data-auto-center="true"
                  >
                    <Map
                      latlng={{
                        lat: Number(curLocGeoCords.split(",")[0]),
                        lng: Number(curLocGeoCords.split(",")[1]),
                      }}
                      zoom={16}
                    />
                  </div>
                </div>
                <div
                  id="search-box"
                  className="absolute left-0 right-0 bottom-40 jsutify-center bg-secondary container mx-auto p-8 z-10 lg:shadow-2xl max-w-3xl"
                >
                  <form
                    id="search-form"
                    className="flex flex-col md:flex-row flex-wrap md:items-center -m-3"
                  >
                    <div className="white-bg w-full">
                      <AutoComplete />
                    </div>
                  </form>
                </div>
              </div>
            )}
            {curLocGeoCords === "" && (
              <div id="slider" className="relative text-white">
                <ImageSlider />
                <div
                  id="search-box-div"
                  className="relative h-600px flex flex-col justify-end md:justify-center z-10"
                >
                  <div className="container mx-auto px-6 flex flex-col flex-1 md:flex-grow-0 md:mb-10 justify-center text-center">
                    <h1 className="font-bold text-3xl lg:text-5xl">
                      Best Deals in One Place
                    </h1>
                    <p className="text-xl">
                      With Locations you can find the best deals in your
                      location.
                    </p>
                  </div>
                  <div
                    id="search-box"
                    className="bg-secondary container mx-auto p-8 z-10 lg:shadow-2xl max-w-3xl"
                  >
                    <form
                      id="search-form"
                      className="flex flex-col md:flex-row flex-wrap md:items-center -m-3"
                    >
                      <div className="white-bg w-full">
                        <AutoComplete />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white">
              <div
                className="container mx-auto px-6 py-20 border-b"
                style={{ borderBottomColor: "rgba(255,255,255,0.2)" }}
              >
                <h1 className="text-4xl mb-6 text-primary">
                  Answering Your Search to ‘Find Parking Near Me’
                </h1>
                <p>
                  No one likes to hunt around all day for a parking spot,
                  especially at an airport. What if you could get online, type
                  something like “find parking near me,” and then instantly have
                  access to your own reserved parking spot in advance? With
                  ParkingSpots, you can eliminate the hassle of searching for
                  parking altogether. With our parking spot finder, you can look
                  up an airport, and even cities, sports city, sports venues,
                  theaters, and more.
                </p>
              </div>
            </div>
            <div className="bg-gray-lighter text-center">
              <div className="container mx-auto px-6 py-20">
                <div className="hidden lg:flex lg:items-center -m-3">
                  <div className="flex-1 p-3">
                    <span className="bg-white inline-flex rounded-full w-20 h-20 items-center justify-center mb-3 ">
                      <span className="text-xl text-secondary font-bold">
                        1
                      </span>
                    </span>
                  </div>
                  <div className="p-3 text-muted">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                  <div className="flex-1 p-3">
                    <span className="bg-white inline-flex rounded-full w-20 h-20 items-center justify-center mb-3 ">
                      <span className="text-xl text-secondary font-bold">
                        2
                      </span>
                    </span>
                  </div>
                  <div className="p-3 text-muted">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                  <div className="flex-1 p-3">
                    <span className="bg-white inline-flex rounded-full w-20 h-20 items-center justify-center mb-3 ">
                      <span className="text-xl text-secondary font-bold">
                        3
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row -m-6">
                  <div
                    className="flex-1 p-6 lg:border-r"
                    style={{ borderRightColor: "rgba(255,255,255,0.2)" }}
                  >
                    <span className="bg-white inline-flex rounded-full w-20 h-20 items-center justify-center mb-3 lg:hidden">
                      <span className="text-xl text-secondary font-bold">
                        1
                      </span>
                    </span>{" "}
                    <h4 className="text-xl mb-2">Find</h4>
                    <p className="leading-snug">
                      Instead of searching “find parking near me”, enter an
                      address or a city to compare hundreds of discounted
                      parking options nearby. Then choose the dates and times
                      you’d like to park. Simply reserve your chosen parking
                      spot.
                    </p>
                  </div>
                  <div className="block lg:hidden p-6 text-muted">
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  <div
                    className="flex-1 p-6 lg:border-r"
                    style={{ borderRightColor: "rgba(255,255,255,0.2)" }}
                  >
                    <span className="bg-white inline-flex rounded-full w-20 h-20 items-center justify-center mb-3 lg:hidden">
                      <span className="text-xl text-secondary font-bold">
                        2
                      </span>
                    </span>{" "}
                    <h4 className="text-xl mb-2">Reserve</h4>
                    <p className="leading-snug">
                      Enter your credit card information and click Reserve Now!
                      You will instantly be emailed a confirmation of your
                      purchase. No more hassle. We make sure your spot is
                      available on the day you have chosen.
                    </p>
                  </div>
                  <div className="block lg:hidden p-6 text-muted">
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  <div className="flex-1 p-6">
                    <span className="bg-white inline-flex rounded-full w-20 h-20 items-center justify-center mb-3 lg:hidden">
                      <span className="text-xl text-secondary font-bold">
                        3
                      </span>
                    </span>
                    <h4 className="text-xl mb-2">Park</h4>
                    <p className="leading-snug">
                      Bring your email confirmation (email or print out) to the
                      garage to start parking. Make sure to check the
                      instructions first as some lots will need a print out that
                      you will put on your dashboard.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white">
              <div className="container mx-auto px-6 py-12 border-t border-gray-light">
                <div className="text-center mb-12">
                  <h2 className="text-2xl text-primary">Browse Our Listings</h2>
                </div>
                <div className="flex flex-wrap -m-3">
                  <div className="w-full lg:w-1/3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="badge-icon mr-2">
                          <FontAwesomeIcon icon={faPlane} className="fa" />
                        </div>
                      </div>
                      <div className="flex-1">
                        <h2
                          className="text-lg text-primary mb-2"
                          style={{ lineheight: "40px" }}
                        >
                          Airport Parking
                        </h2>
                        <AirportParkings />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="badge-icon mr-2">
                          <FontAwesomeIcon icon={faList} className="fa" />
                        </div>
                      </div>
                      <div className="flex-1">
                        <h2
                          className="text-lg text-primary mb-2"
                          style={{ lineheight: "40px" }}
                        >
                          Stadium Parking
                        </h2>
                        <StadiumParkings />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="badge-icon mr-2">
                          <FontAwesomeIcon icon={faHeart} className="fa" />
                        </div>
                      </div>
                      <div className="flex-1">
                        <h2
                          className="text-lg text-primary mb-2"
                          style={{ lineheight: "40px" }}
                        >
                          Popular Cities
                        </h2>
                        <PopularCities />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {featuredSpots.length > 0 && (
              <div className="bg-secondary text-white">
                <div className="container mx-auto px-6 py-12">
                  <FeaturedSpots featuredSpots={featuredSpots} />
                </div>
              </div>
            )}
            {/* <div className="bg-gray-lighter">
              <div className="container mx-auto px-6 py-12">
                <div className="flex flex-wrap md:items-center -m-3">
                  <div className="w-full md:w-1/2 p-3">
                    <h2 className="font-medium text-xl">
                      Subscribe and be notified about new locations.
                    </h2>
                  </div>
                  <div className="w-full md:w-1/2 p-3">
                    <form className="flex border-b-2 border-gray-light">
                      <input
                        className="bg-transparent px-3 py-2 flex-1 focus:outline-none"
                        placeholder="Your Email Address"
                      />
                      <button
                        className="px-3 py-2 hover:bg-primary hover:text-white"
                        type="submit"
                      >
                        <FontAwesomeIcon
                          icon={faLongArrowAltRight}
                          size="xs"
                          className="fa"
                        />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
          </main>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Home;
