export const GOOGLE_API_KEY = "AIzaSyAT89k3xIt6zfbf3p377JuGYhPvygFQ0-c";
export const BASE_URL = "//parkingspots.com"; //Live server
// export const BASE_URL = "//parkingspot.webieon.com"; //UAT server
// export const BASE_URL = "//localhost:8080"; //Local System

// opens google map
export const GOOGLE_MAP_URL =
  "https://www.google.com/maps/search/?api=1&query=";

//Place History Status
export const DIRECTION = "direction";
export const CONTACT = "contact";

//popular cities, details updated through GeoCoding API
export const POPULAR_CITIES = [
  {
    name: "Atlanta",
    lat_lng: "33.748752,-84.38768449999999",
    opt_val: "Atlanta, GA, USA",
  },
  {
    name: "Austin",
    lat_lng: "30.267153,-97.7430608",
    opt_val: "Austin, TX, USA",
  },
  {
    name: "Baltimore",
    lat_lng: "39.2903848,-76.6121893",
    opt_val: "Baltimore, MD, USA",
  },
  {
    name: "Boston",
    lat_lng: "42.3600825,-71.0588801",
    opt_val: "Boston, MA, USA",
  },
  {
    name: "Buffalo",
    lat_lng: "42.88644679999999,-78.8783689",
    opt_val: "Buffalo, NY, USA",
  },
  {
    name: "Charlotte",
    lat_lng: "35.2270869,-80.8431267",
    opt_val: "Charlotte, NC, USA",
  },
  {
    name: "Chicago",
    lat_lng: "41.8781136,-87.6297982",
    opt_val: "Chicago, IL, USA",
  },
  {
    name: "Dallas",
    lat_lng: "32.7766642,-96.79698789999999",
    opt_val: "Dallas, TX, USA",
  },
  {
    name: "Denver",
    lat_lng: "39.7392358,-104.990251",
    opt_val: "Denver, CO, USA",
  },
  {
    name: "Detroit",
    lat_lng: "42.331427,-83.0457538",
    opt_val: "Detroit, MI, USA",
  },
  {
    name: "Houston",
    lat_lng: "29.7604267,-95.3698028",
    opt_val: "Houston, TX, USA",
  },
  {
    name: "Indianapolis",
    lat_lng: "39.768403,-86.158068",
    opt_val: "Indianapolis, IN, USA",
  },
  {
    name: "Las Vegas",
    lat_lng: "36.171563,-115.1391009",
    opt_val: "Las Vegas, NV, USA",
  },
  {
    name: "Los Angeles",
    lat_lng: "34.0522342,-118.2436849",
    opt_val: "Los Angeles, CA, USA",
  },
  {
    name: "Miami",
    lat_lng: "25.7616798,-80.1917902",
    opt_val: "Miami, FL, USA",
  },
  {
    name: "Milwaukee",
    lat_lng: "43.0389025,-87.9064736",
    opt_val: "Milwaukee, WI, USA",
  },
  {
    name: "Minneapolis",
    lat_lng: "44.977753,-93.2650108",
    opt_val: "Minneapolis, MN, USA",
  },
  {
    name: "Nashville",
    lat_lng: "36.1626638,-86.7816016",
    opt_val: "Nashville, TN, USA",
  },
  {
    name: "New Orleans",
    lat_lng: "29.95106579999999,-90.0715323",
    opt_val: "New Orleans, LA, USA",
  },
  {
    name: "Newark",
    lat_lng: "40.735657,-74.1723667",
    opt_val: "Newark, NJ, USA",
  },
];

//Stadiums
export const STADIUMS = [
  {
    name: "American Airlines Arena",
    lat_lng: "25.7814014,-80.18696899999999",
    opt_val: "American Airlines Arena, Biscayne Boulevard, Miami, FL, USA",
  },
  {
    name: "American Airlines Center",
    lat_lng: "32.7903908,-96.8102547",
    opt_val: "American Airlines Center, Victory Avenue, Dallas, TX, USA",
  },
  {
    name: "AT&T Stadium",
    lat_lng: "32.7472844,-97.09449389999999",
    opt_val: "AT&T Stadium, AT&T Way, Arlington, TX, USA",
  },
  {
    name: "Bank of America Stadium",
    lat_lng: "35.2257795,-80.852965",
    opt_val: "Bank of America Stadium, South Mint Street, Charlotte, NC, USA",
  },
  {
    name: "Bankers Life Fieldhouse",
    lat_lng: "39.7640434,-86.1555367",
    opt_val:
      "Bankers Life Fieldhouse, South Pennsylvania Street, Indianapolis, IN, USA",
  },
  {
    name: "Barclays Center",
    lat_lng: "40.6826465,-73.97541559999999",
    opt_val: "Barclays Center, Atlantic Avenue, Brooklyn, NY, USA",
  },
  {
    name: "Bridgestone Arena",
    lat_lng: "36.1591736,-86.7784959",
    opt_val: "Bridgestone Arena, Broadway, Nashville, TN, USA",
  },
  {
    name: "Busch Stadium",
    lat_lng: "38.6226188,-90.1928209",
    opt_val: "Busch Stadium, Clark Avenue, St. Louis, MO, USA",
  },
  {
    name: "Camden Yards",
    lat_lng: "39.28381940000001,-76.62167819999999",
    opt_val: "Camden Yards, West Camden Street, Baltimore, MD, USA",
  },
  {
    name: "Capital One Arena",
    lat_lng: "38.8981675,-77.02085679999999",
    opt_val: "Capital One Arena, F Street Northwest, Washington, DC, USA",
  },
  {
    name: "CenturyLink Field",
    lat_lng: "47.5951518,-122.3316393",
    opt_val: "CenturyLink Field, Occidental Avenue South, Seattle, WA, USA",
  },
  {
    name: "Chase Field",
    lat_lng: "33.4453344,-112.0667091",
    opt_val: "Chase Field, East Jefferson Street, Phoenix, AZ, USA",
  },
  {
    name: "Chesapeake Energy Arena",
    lat_lng: "35.4634247,-97.5151138",
    opt_val: "Paycom Center, West Reno Avenue, Oklahoma City, OK, USA",
  },
  {
    name: "Citi Field",
    lat_lng: "40.7570877,-73.8458213",
    opt_val: "Citi Field, Seaver Way, Queens, NY, USA",
  },
  {
    name: "Citizens Bank Park",
    lat_lng: "39.9060572,-75.1664952",
    opt_val: "Citizens Bank Park, Citizens Bank Way, Philadelphia, PA, USA",
  },
  {
    name: "Comerica Park",
    lat_lng: "42.33899840000001,-83.0485197",
    opt_val: "Comerica Park, Woodward Avenue, Detroit, MI, USA",
  },
  {
    name: "Coors Field",
    lat_lng: "39.7558823,-104.9941781",
    opt_val: "Coors Field, Blake Street, Denver, CO, USA",
  },
  {
    name: "Dodger Stadium",
    lat_lng: "34.0749209,-118.244901",
    opt_val: "Dodger Stadium, Vin Scully Ave, Los Angeles, CA, USA",
  },
  {
    name: "Enterprise Center",
    lat_lng: "38.6268402,-90.20267830000002",
    opt_val: "Enterprise Center, Clark Avenue, St. Louis, MO, USA",
  },
  {
    name: "FedExField",
    lat_lng: "38.9077031,-76.8645064",
    opt_val: "FedExField, FedEx Way, North Englewood, MD, USA",
  },
];

//Airports
export const AIRPORTS = [
  {
    name: "Austin-Bergstrom International Airport",
    lat_lng: "30.19747109999999,-97.66635289999999",
    opt_val:
      "Austin-Bergstrom International Airport (AUS), Presidential Boulevard, Austin, TX, USA",
  },
  {
    name: "Baltimore-Washington International Airport",
    lat_lng: "39.1799695,-76.6717378",
    opt_val:
      "Baltimore/Washington International Airport (BWI), Friendship Road, Baltimore, MD, USA",
  },
  {
    name: "Boston Logan International Airport",
    lat_lng: "42.3656132,-71.0095602",
    opt_val: "Boston Logan International Airport (BOS), Boston, MA, USA",
  },
  {
    name: "Bradley International Airport (BDL)",
    lat_lng: "41.9310146,-72.68245499999999",
    opt_val:
      "Bradley International Airport (BDL), Schoephoester Road, Windsor Locks, CT, USA",
  },
  {
    name: "Buffalo Niagara International Airport",
    lat_lng: "42.9397059,-78.7295067",
    opt_val:
      "Buffalo Niagara International Airport (BUF), Genesee Street, Buffalo, NY, USA",
  },
  {
    name: "Burlington International Airport",
    lat_lng: "44.4706939,-73.1516037",
    opt_val:
      "Burlington International Airport (BTV), Airport Drive, South Burlington, VT, USA",
  },
  {
    name: "Charleston",
    lat_lng: "32.7764749,-79.93105120000001",
    opt_val: "Charleston, SC, USA",
  },
  {
    name: "Charlotte Douglas International Airport",
    lat_lng: "35.2144026,-80.9473146",
    opt_val:
      "Charlotte Douglas International Airport (CLT), Josh Birmingham Parkway, Charlotte, NC, USA",
  },
  {
    name: "Chicago Midway International Airport",
    lat_lng: "41.7883503,-87.7417559",
    opt_val:
      "Midway International Airport Terminal, Cicero Avenue, Chicago, IL, USA",
  },
  {
    name: "Chicago O'Hare International Airport",
    lat_lng: "41.98028,-87.9089979",
    opt_val:
      "O'Hare International Airport (ORD), West Balmoral Avenue, Chicago, IL, USA",
  },
  {
    name: "Cleveland Hopkins International Airport",
    lat_lng: "41.4057985,-81.8538669",
    opt_val:
      "Cleveland Hopkins International Airport (CLE), Riverside Drive, Cleveland, OH, USA",
  },
  {
    name: "Dallas Love Field Airport",
    lat_lng: "32.8481029,-96.8512063",
    opt_val:
      "Dallas Love Field Airport (DAL), Herb Kelleher Way, Dallas, TX, USA",
  },
  {
    name: "Dallas/Fort Worth International Airport",
    lat_lng: "32.8998091,-97.0403352",
    opt_val:
      "Dallas/Fort Worth International Airport (DFW), Aviation Drive, DFW Airport, TX, USA",
  },
  {
    name: "Dayton International Airport",
    lat_lng: "39.90252419999999,-84.2217719",
    opt_val:
      "Dayton International Airport (DAY), Terminal Drive, Dayton, OH, USA",
  },
  {
    name: "Denver International Airport",
    lat_lng: "39.8494465,-104.6735134",
    opt_val: "Denver International Airport (DEN), Peña Blvd, Denver, CO, USA",
  },
  {
    name: "Detroit Metropolitan Wayne County Airport",
    lat_lng: "42.2162491,-83.3553642",
    opt_val:
      "Detroit Metropolitan Wayne County Airport, W. G. Rogell Dr., Detroit, MI, USA",
  },
  {
    name: "El Paso International Airport",
    lat_lng: "31.8053354,-106.3824345",
    opt_val:
      "El Paso International Airport (ELP), Convair Rd, El Paso, TX, USA",
  },
  {
    name: "Fort Lauderdale-Hollywood International Airport",
    lat_lng: "26.0742344,-80.1506022",
    opt_val:
      "Fort Lauderdale-Hollywood International Airport (FLL), Terminal Drive, Fort Lauderdale, FL, USA",
  },
  {
    name: "General Mitchell International Airport",
    lat_lng: "42.9475534,-87.89664599999999",
    opt_val:
      "General Mitchell International Airport (MKE), South Howell Avenue, Milwaukee, WI, USA",
  },
  {
    name: "George Bush Intercontinental Airport",
    lat_lng: "29.9902199,-95.3367827",
    opt_val:
      "George Bush Intercontinental Airport (IAH), North Terminal Road, Houston, TX, USA",
  },
];
