import { configureStore } from "@reduxjs/toolkit";
import spotsReducer from "./spots";
import modalReducer from "./popup-modal";
import uiReducer from "./ui-states";
import directionReducer from "./direction";

const store = configureStore({
  reducer: {
    parking: spotsReducer,
    modal: modalReducer,
    uiStates: uiReducer,
    directionProps: directionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
