import { useSelector, useDispatch } from "react-redux";
import Header from "../layout/Header";
import AutoComplete from "../components/AutoComplete";
import GoogleMap from "../components/GoogleMap";
import "../index.css";
import "../App.css";
import "./ParkingSpots.css";
import SpotsList from "../components/SpotsList";
import { useHttp } from "../hooks/use-http";
import { spotsAction, uiAction, directionAction } from "../store/store-actions";
import Modal from "../components/Modal";
import $ from "jquery";
import "malihu-custom-scrollbar-plugin";
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import "jquery-mousewheel";

const ParkingSpots = () => {
  $(function () {
    $(".mcsroll").mCustomScrollbar({
      theme: "light",
      scrollbarPosition: "inside",
      mouseWheel: {
        enable: true,
      },
    });

    //sets height for malihu scroll bar
    let maxHeight = Number($(window).height()) - 196;
    $(".mcsroll").css("max-height", `${maxHeight}px`);

    //updates malihu scroll bar height
    $(window).on("resize", function () {
      let maxHeight = Number($(window).height()) - 196;
      $(".mcsroll").css("max-height", `${maxHeight}px`);
    });
  });

  const dispatch = useDispatch();
  const sendRequest = useHttp();
  const {
    loaderStatus,
    curLocGeoCords,
    searchedLocGeoCords,
    searchText,
    spots,
    moreResultsCount,
    modalIsOpen,
    modalContent,
  } = useSelector((state) => {
    return {
      loaderStatus: state.uiStates.isLoading,
      curLocGeoCords: state.uiStates.curLocGeoCords.lat_lng,
      searchedLocGeoCords: state.uiStates.searchedLocGeoCords.lat_lng,
      searchText: state.uiStates.searchText,
      spots: state.parking.spots,
      moreResultsCount: state.uiStates.moreResultsCount,
      modalIsOpen: state.modal.isOpen,
      modalContent: state.modal.modalContent,
    };
  });

  const updateSpots = (data) => {
    dispatch(spotsAction.updateSpots(data.spots));
    dispatch(uiAction.setMoreResultsCount(Number(data.pageCount)));
  };

  const getMoreParkingSpots = (event) => {
    event.preventDefault();
    dispatch(directionAction.setResponse(null));
    sendRequest(
      {
        url: "getMoreParkingSpots.json",
        method: "POST",
        data: {
          current_lat_lng: curLocGeoCords,
          searched_lat_lng: searchedLocGeoCords,
          pageCount: moreResultsCount,
          searchText,
        },
      },
      updateSpots
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      {modalIsOpen && modalContent !== null && (
        <Modal content={modalContent} modalStatus={modalIsOpen} />
      )}
      <Header />
      <div id="content" className="flex flex-col flex-1">
        <div className="flex flex-1">
          <GoogleMap />
          <div className="flex flex-col flex-1 py-6 px-4 bg-secondary relative z-20 shadow-2xl md:max-w-480px">
            <div className="bg-white mb-2">
              <AutoComplete />
            </div>
            <header className="mb-4 text-white">
              <h2 className="text-lg lg:text-xl inline-block ">
                Results (
                <span className="results">
                  {spots !== null && spots.length}
                </span>
                )
              </h2>
              {moreResultsCount > 0 && (
                <p
                  className="text-lg lg:text-xl inline-block float-right cursor-pointer"
                  onClick={getMoreParkingSpots}
                >
                  More Results
                </p>
              )}
            </header>
            <div className="flex-1 relative mcsroll">
              <div id="spots" className="top-0 left-0 w-full h-full">
                {!loaderStatus && spots.length > 0 && <SpotsList />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParkingSpots;
