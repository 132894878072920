import { Fragment } from "react";
import Header from "../layout/Header";
import SubHeader from "../layout/SubHeader";
import Footer from "../layout/Footer";

const Environment = () => {
  return (
    <Fragment>
      <Header />
      <div id="content" className="flex flex-col flex-1">
        <SubHeader title="Environment" />
        <main id="main">
          <div className="container mx-auto px-6 py-20">
            <div>
              It is not news that vehicle emissions have an enormous impact on
              the environment. Technological advances are making a positive
              difference, however, it takes time for society to adopt those
              changes particularly when there is an obvious individual cost
              associated with making a change. The big wheel moves slowly to say
              the least.
              <br />
              <br />
            </div>
            <div>
              One thing that can make a difference in the short term is simply
              redesigning the process to work with existing technology or
              current methods. Offering alternatives without impacting cost or
              the final outcome is where technology is playing a large role in
              reducing the time it takes for drivers to locate available parking
              spots. Sites such as ours are taking the technology to a level
              where drivers can save time and money by using the online service
              to locate, reserve and pay for parking without even starting the
              car. Looking for a parking spot can be glacial – but it doesn’t
              have to be.
              <br />
              <br />
            </div>
            <div>
              <strong>
                Check out what others are saying:
                <br />
              </strong>
              <br />
            </div>
            <div>
              The hunt for parking spaces is not just frustrating, it is a major
              contributor to congestion, says Paul Watters of the UK’s
              Automobile Association. “Most people are creatures of habit and
              like to park in the same area, so if their preferred spot doesn’t
              have any spaces they will often drive around waiting for one to
              become free rather than searching further afield.”
              <br />
              <br />
            </div>
            <div>
              Source:{" "}
              <a href="http://www.newscientist.com/article/mg21328506.100-parking-sensors-to-take-pain-out-of-finding-a-space.html#.Uw9h8vR5MdJ">
                NewsScientist
                <br />
              </a>
              <br />
            </div>
            <div>
              That means more emissions. According to a 2007 study by Donald
              Shoup at the University of California, Los Angeles, drivers in a
              15-block district of LA notched up a staggering 1.5 million
              kilometers a year looking for parking spaces. That’s the
              equivalent of 38 trips around the Earth, 178,000 liters of wasted
              gasoline and 662 tons of carbon dioxide.
              <br />
              <br />
            </div>
            <div>
              Source:{" "}
              <a href="http://shoup.bol.ucla.edu/CruisingForParkingAccess.pdf">
                UCLA
                <br />
              </a>
              <br />
            </div>
            <div>
              As the majority of the world’s population moves to metropolitan
              areas, key city systems, including city streets and transportation
              systems, are being strained to the breaking point. Additionally,
              vehicle emissions resulting from drivers looking for parking are
              so closely linked that a year-long study found that drivers in a
              15 block district in Los Angeles drove in excess of 950,000 miles,
              produced 730 tons of carbon dioxide and used 47,000 gallons of gas
              searching for parking. As well as frazzling tempers, this circling
              produces copious pollution and greenhouse gas emissions. Around
              the world, commuters deal with the daily struggle of finding a
              parking space. In fact, experts estimate that this causes 30
              percent of urban traffic congestion. A global survey of commuters
              in 20 international cities conducted by IBM found that nearly six
              out of 10 drivers had abandoned their search for a parking space
              at least once and drivers have spent an average of nearly 20
              minutes in pursuit of a coveted spot. Studies further suggest
              nearly one-third of downtown traffic consists of drivers seeking
              parking spots. During peak shopping times it’s likely the same
              story on those paved acreages surrounding suburban shopping malls.
              <br />
              <br />
            </div>
            <div>
              Source:{" "}
              <a href="http://www-03.ibm.com/press/us/en/press release/35514.wss">
                IBM
                <br />
              </a>
              <br />
            </div>
            <div>
              In congested urban areas parking of motor vehicles is
              time-consuming and sometimes expensive. Urban planners must
              consider whether and how to accommodate or ‘demand manage’
              potentially large numbers of motor vehicles in small geographic
              areas. Usually the authorities set minimum, or more rarely
              maximums, of the number of motor vehicle parking spaces for new
              housing and commercial developments, and may also plan its
              location and distribution to influence its convenience and
              accessibility. Many building owners now prefer to use the space
              for higher-yield commercial use than car park use. This opens up
              the door even further for drivers to consider residential parking
              spaces as a viable option.
              <br />
              <br />
            </div>
            <div>
              Source: <a href="http://parking.askdefine.com/">AskDefine</a>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Environment;
