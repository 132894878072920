import { Fragment } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../config";

const Map = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  return (
    <Fragment>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={props.latlng}
          zoom={props.zoom}
        >{props.children}</GoogleMap>
      )}
    </Fragment>
  );
};

export default Map;
