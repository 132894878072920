import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  curLocGeoCords: { lat_lng: ""},
  searchedLocGeoCords: { lat_lng: "" },
  searchText: "",
  moreResultsCount: 0,
  postCode: "",
  postCodeSearched: false,
};

const uiSlice = createSlice({
  name: "ui-states",
  initialState,
  reducers: {
    setLoaderStatus(state, action) {
      state.isLoading = action.payload;
    },
    setCurLocGeoCords(state, action) {
      state.curLocGeoCords.lat_lng = action.payload;
    },
    setSearchedLocGeoCords(state, action) {
      state.searchedLocGeoCords.lat_lng = action.payload;
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    setMoreResultsCount(state, action) {
      state.moreResultsCount = action.payload;
    },
    setPostCode(state, action) {
      state.postCode = action.payload;
    },
    setPostCodeSearched(state, action) {
      state.postCodeSearched = action.payload;
    }
  },
});

export const uiAction = uiSlice.actions;
export default uiSlice.reducer;
