import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./screens/Home";
import ParkingSpots from "./screens/ParkingSpots";
import WhoAndWhatWeAre from "./screens/WhoAndWhatWeAre";
import WorldOfParking from "./screens/WorldOfParking";
import Environment from "./screens/Environment";
import ContactUs from "./screens/ContactUs";

const App = () => {
  return (
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/parkingSpots">
          <ParkingSpots />
        </Route>
        <Route path="/whoAndWhatWeAre">
          <WhoAndWhatWeAre />
        </Route>
        <Route path="/worldOfParking">
          <WorldOfParking />
        </Route>
        <Route path="/environment">
          <Environment />
        </Route>
        <Route path="/contactUs">
          <ContactUs />
        </Route>
        <Route path="*">
          <Redirect to="/home" />
        </Route>
      </Switch>
  );
};

export default App;
