import { Fragment } from "react";
import  { $ } from "react-jquery-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const FeaturedSpots = (props) => {
  const responsiveProps = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  };

  $(function () {
    let owl = $(".owl-carousel");
    owl.owlCarousel();

    owl.on("mousewheel", ".owl-stage", function (e) {
      if (e.originalEvent.wheelDelta >= 0) {
        owl.trigger("next.owl");
      } else {
        owl.trigger("prev.owl");
      }
      e.preventDefault();
    });

    $(".customNextBtn").on("click", function () {
      owl.trigger("next.owl.carousel");
    });

    $(".customPrevBtn").on("click", function () {
      owl.trigger("prev.owl.carousel");
    });
  });

  return (
    <Fragment>
      <header className="flex justify-between mb-4 items-center">
        <h2 className="text-2xl">Featured Spots</h2>
        <div className="flex flex-wrap -m-1">
          <span className="btn-icon-round-white m-1 customPrevBtn cursor-pointer">
            <FontAwesomeIcon icon={faChevronLeft} transform="down-5#" />
          </span>
          <span className="btn-icon-round-white m-1 customNextBtn cursor-pointer">
            <FontAwesomeIcon icon={faChevronRight} transform="down-5#" />
          </span>
        </div>
      </header>
      <OwlCarousel
        id="carousel"
        className="owl-theme owl-stage"
        loop={true}
        margin={10}
        dots={true}
        dotsEach={true}
        responsiveClass={true}
        responsive={responsiveProps}
        autoplay={true}
        autoplayHoverPause={true}
        autoplayTimeout={10000}
      >
        {props.featuredSpots.map((spot) => {
          return (
            <div className="card card-hover" key={spot.id}>
              <div
                className="card-bg"
                style={{
                  backgroundImage: `url(${spot.place_photos[0].prefix}original${spot.place_photos[0].suffix})`,
                }}
              ></div>
              <div className="card-body">
                <h3 className="font-medium text-lg">{spot.name}</h3>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </Fragment>
  );
};

export default FeaturedSpots;
