import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  spots: [],
  featuredSpots: [],
};

const spotsSlice = createSlice({
  name: "spots",
  initialState,
  reducers: {
    setSpots(state, action) {
      state.spots = action.payload;
    },
    updateSpots(state, action) {
      state.spots = [...state.spots, ...action.payload];
    },
    setFeaturedSpots(state, action) {
      state.featuredSpots = action.payload;
    },
  },
});

export const spotsAction = spotsSlice.actions;
export default spotsSlice.reducer;
