import { Fragment } from "react";
import Header from "../layout/Header";
import SubHeader from "../layout/SubHeader";
import Footer from "../layout/Footer";

const WhoAndWhatWeAre = () => {
  return (
    <Fragment>
      <Header />
      <div id="content" className="flex flex-col flex-1">
        <SubHeader title="Who &amp; What we are" />
        <main id="main">
          <div className="container mx-auto px-6 py-20">
            <div>
              ParkingSpots is an aggregator platform. That means we draw from
              numerous major parking spots providers to offer a consolidated and
              comprehensive selection of commercial and residential parking
              spots in major cities throughout North America. Consumers have the
              largest possible selection of parking spots in locations specific
              to their requirements. ParkingSpots does the heavy lifting for you
              and works with the best parking spots providers to deliver parking
              spaces that you’ll be interested in. We then push that information
              through to you in an attractive, tiled interface that lets you
              quickly tap a link to reserve your parking spots. No muss – no
              fuss. ParkingSpots provides features that make navigating to your
              next parking spot much faster and easier than ever before. We are
              able to deliver enhanced guidance, integrated payment options,
              consumer feedback, and a host of capabilities–creating a fully
              integrated consumer smart parking platform. Using our service, you
              can search for your ideal parking spot at the price you want to
              pay. Once you locate the perfect parking spot on parkingspots.com
              you simply reserve and pay.
              <br />
              <br />
            </div>
            <div>
              <strong>
                OUR VISION 2<br />
              </strong>
              <br />
            </div>
            <div>
              To be the company that best understands and satisfies product,
              service, innovation and commerce in the parking industry.
              <br />
              <br />
            </div>
            <div>
              <strong>
                OUR MISSION
                <br />
              </strong>
              <br />
            </div>
            <div>
              To connect drivers with parking spots. The company is dedicated to
              providing the highest quality products and services in accordance
              with choice, convenience and value for drivers of all types of
              vehicles.
              <br />
              <br />
            </div>
            <div>
              <strong>
                OUR CORE VALUES
                <br />
              </strong>
              <br />
            </div>
            <div>
              <em>Safety, Reliability And Efficiency</em> – We believe we earn
              employee and customer respect by operating safely, reliably and
              efficiently.
              <br />
              <br />
            </div>
            <div>
              <em>Caring</em> – We care about our people. We foster and respect
              their talents and value their contribution to the company’s
              ongoing success.
              <br />
              <br />
            </div>
            <div>
              <em>Relationships</em> – We are proud of the solid long-term
              relationships we are forging with our employees, customers and the
              communities in which we operate. Our success is built on a
              platform of integrity, partnership and respect.
              <br />
              <br />
            </div>
            <div>
              <em>Innovation and Leadership</em> – We believe innovative
              thinking, efficient and nimble actions, leadership skills and
              adaptability drive our business success.
              <br />
              <br />
            </div>
            <div>
              <em>Environmental Stewardship</em> – We are committed to
              environmental stewardship, contributing to building sustainable
              communities and helping to create a culture of energy
              conservation.
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default WhoAndWhatWeAre;
