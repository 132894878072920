import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useHttp } from "../hooks/use-http";
import { uiAction, spotsAction } from "../store/store-actions";
import { STADIUMS } from "../config";

const StadiumParkings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sendRequest = useHttp();
  const curLocGeoCords = useSelector(
    (state) => state.uiStates.curLocGeoCords.lat_lng
  );

  const applyData = (data) => {
    dispatch(spotsAction.setSpots(data.spots));
    dispatch(uiAction.setMoreResultsCount(Number(data.pageCount)));
    history.push("/parkingSpots");
  };

  const getStadiumParkingSpots = (stadium) => {
    dispatch(uiAction.setSearchedLocGeoCords(stadium.lat_lng));
    dispatch(uiAction.setPostCodeSearched(false));
    dispatch(uiAction.setSearchText(stadium.opt_val));
    sendRequest(
      {
        url: `getParkingSpots.json`,
        method: "POST",
        data: {
          current_lat_lng: curLocGeoCords,
          searched_lat_lng: stadium.lat_lng,
          searchText: stadium.opt_val,
          postCodeSearched: false,
        },
      },
      applyData
    );
  };

  return (
    <Fragment>
      <ul>
        {STADIUMS.map((stadium, idx) => (
          <li key={idx}>
            <p
              className="text-sm leading-loose hover:text-primary cursor-pointer"
              onClick={getStadiumParkingSpots.bind(null, stadium)}
            >
              {stadium.name}
            </p>
          </li>
        ))}
      </ul>
    </Fragment>
  );
};

export default StadiumParkings;
