import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import StarRatings from "react-star-ratings";
import { internalIpV4 } from "internal-ip";

export const getCurrentUserIp = async () => {
  return await internalIpV4();
};

export const getFormattedDate = (date) => {
  let month = date.toLocaleString("en-US", { month: "short" });
  let day = date.toLocaleString("en-US", { day: "numeric" });
  let year = date.toLocaleString("en-US", { year: "numeric" });
  return `${month} ${day} ${year}`;
};

export const getCurrentLocation = () => {
  navigator.geolocation.getCurrentPosition((position) => {
    let lat_lng = `${position.coords.latitude},${position.coords.longitude}`;
    localStorage.setItem("current_location_latlng", lat_lng);
    return lat_lng;
  });
};

export const autoScroll = (fsq_id) => {
  $("#mcscroll").mCustomScrollbar("scrollTo", $("#" + fsq_id));
};

export const getDetailsModalContent = (spot) => {
  $(function () {
    $("[data-tabs] .tabs-item p").on("click", function (e) {
      e.preventDefault();
      var item = $(this).closest(".tabs-item");
      var parent = item.closest("[data-tabs]");
      var target = $(parent.data("tabs"));

      if (item.hasClass("active")) {
        return;
      }

      parent.find(".tabs-item").removeClass("active");
      item.addClass("active");
      target.find("> div").addClass("hidden");
      target.find("> div").eq(item.index()).removeClass("hidden");
    });
  });

  return (
    <Fragment>
      <div className="flex justify-between items-center">
        <h5 className="font-bold logo-color -mx-1">{spot.name}</h5>
      </div>
      <StarRatings
        rating={Number((Number(spot.rating) / 2).toFixed(1))}
        starRatedColor="#d9a527"
        numberOfStars={5}
        starDimension="17px"
        starSpacing="3px"
        name={`rating_${spot.fsq_id}`}
      />
      <ul className="tabs mt-3" data-tabs="#modal-tabs">
        <li key={`${spot.fsq_id}desc`} className="tabs-item active">
          <p>Spot Details</p>
        </li>
        <li key={`${spot.fsq_id}hrs`} className="tabs-item">
          <p>Access Hours</p>
        </li>
        <li key={`${spot.fsq_id}reviews`} className="tabs-item">
          <p>Reviews</p>
        </li>
      </ul>
      <div id="modal-tabs">
        <div className="overflow-pop-up">
          <p className="mb-2">{spot.location.formatted_address}</p>
          {spot.description && <p>{spot.description}</p>}
        </div>
        <div className="overflow-pop-up hidden">
          {spot.opening_hours &&
            spot.opening_hours.split(";").map((hour) => <p>{hour}</p>)}
          {spot.opening_hours === null && <p>No Data Available</p>}
        </div>
        <div className="overflow-pop-up hidden">
          {spot.place_tips.length > 0 && (
            <ul>
              {spot.place_tips.map((tip) => (
                <>
                  <small className="relative italic font-bold text-gray-medium text-xs top-4">
                    {getFormattedDate(new Date(tip.created_at))}
                  </small>
                  <p className="mb-1 leading-none">{tip.text}</p>
                </>
              ))}
            </ul>
          )}
          {spot.place_tips.length === 0 && <p>No Data Available</p>}
        </div>
      </div>
    </Fragment>
  );
};

export const getContactModalContent = (spot) => {
  return (
    <Fragment>
      <div className="items-center mb-4">
        <h5 className="font-bold logo-color mb-2 -mx-1">{spot.name}</h5>
        <div className="flex items-center justify-start">
          <a href={`tel:${spot.tel}`}>
            <FontAwesomeIcon
              icon={faPhone}
              className="mr-2"
              style={{ color: "#022ab3" }}
            />
            {spot.tel}
          </a>
        </div>
      </div>
    </Fragment>
  );
};
