import { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
// import axios from "axios";

const ContactUs = () => {
  // const contactUsSubmitAction = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post(`${BASE_URL}/contactUs.json`, { test: "yash" })
  //     .then((resp) => console.log(resp));
  // };

  return (
    <Fragment>
      <Header />
      <div id="content" className="flex flex-col flex-1">
        <header id="page-header" className="border-b-2 border-gray-lighter">
          <div className="container mx-auto px-6 py-4">
            <ul className="flex text-xs uppercase -m-1">
              <li className="p-1">
                <Link to="/home" className="text-primary">
                  HOME
                </Link>
              </li>
              <li className="p-1 text-gray-light">/</li>
              <li className="p-1">
                <span>Contact Us</span>
              </li>
            </ul>
            <div className="flex flex-wrap flex-col md:flex-row md:items-center md:justify-between -m-2 py-3">
              <div className="p-2 flex-1">
                <h1 className="text-3xl text-primary">Contact Us</h1>
              </div>
              <div className="p-2">
                <div className="flex items-center text-muted">
                  <FontAwesomeIcon
                    icon={faComments}
                    size="4x"
                    className="text-6xl mr-2"
                  />
                  <a href="tel:2283418068" className="leading-tight">
                    <span className="block text-sm">24/7 Support</span>
                    <span className="block text-xl">228-341-8068</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="bg-primary h-300px relative">
          <div
            id="map"
            className="absolute top-0 right-0 bottom-0 left-0"
          ></div>
        </div>

        <main id="main">
          <div className="container mx-auto px-6 py-20">
            <div className="flex flex-wrap -m-3">
              <div className="w-full lg:w-1/4 p-3 flex flex-col">
                <h2 className="font-bold text-primary mb-4">
                  Contact Information
                </h2>
                <div className="bg-gray-lightest p-6 flex-1">
                  <div className="-my-3">
                    <dl className="py-3">
                      <dt className="font-bold text-sm">Location</dt>
                      <dd>4758 Nancy Street</dd>
                    </dl>
                    <dl className="py-3">
                      <dt className="font-bold text-sm">Phone Number</dt>
                      <dd>+1 919-571-2528</dd>
                    </dl>
                    <dl className="py-3">
                      <dt className="font-bold text-sm">Email</dt>
                      <dd>
                        <a
                          className="text-primary"
                          href="mailto:hello@example.com"
                        >
                          hello@example.com
                        </a>
                      </dd>
                    </dl>
                    <dl className="py-3">
                      <dt className="font-bold text-sm">Customer Care</dt>
                      <dd>
                        <a
                          className="text-primary"
                          href="mailto:hello@example.com"
                        >
                          hello@example.com
                        </a>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/4 p-3">
                <h2 className="font-bold text-primary mb-4">Form</h2>
                {/* <form method="post" onSubmit={contactUsSubmitAction}> */}
                <form method="post">
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="eV42IdN6sDhbbf2gJ2QgL9OrxvZouxIkpLCAatKo"
                  />
                  <div className="flex flex-wrap -mx-2">
                    <div className="mb-4 w-full md:w-1/2 px-2">
                      <label
                        className="block uppercase font-semibold text-xs rounded mb-2"
                        htmlFor="name"
                      >
                        Name
                      </label>

                      <input
                        id="name"
                        className="border border-gray-light block w-full p-2 focus:outline-none focus:border-primary"
                        name="name"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                    <div className="mb-4 w-full md:w-1/2 px-2">
                      <label
                        className="block uppercase font-semibold text-xs rounded mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>

                      <input
                        id="email"
                        className="border border-gray-light block w-full p-2 focus:outline-none focus:border-primary"
                        name="email"
                        type="email"
                        defaultValue=""
                      />
                    </div>
                    <div className="mb-4 w-full px-2">
                      <label
                        className="block uppercase font-semibold text-xs rounded mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>

                      <textarea
                        id="message"
                        className="border border-gray-light block w-full p-2 focus:outline-none focus:border-primary"
                        name="message"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <button className="btn-submit" type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactUs;
