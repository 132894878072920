import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  modalContent: null,
  spot: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModalStatus(state, action) {
      state.isOpen = action.payload;
    },
    setModalContent(state, action) {
        state.modalContent = action.payload;
    },
    setSpot(state, action) {
      state.spot = action.payload;
    }
  },
});

export const modalAction = modalSlice.actions;
export default modalSlice.reducer;
