import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  MarkerClusterer,
  Marker,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import Map from "../components/Map";
import marker from "../assets/images/marker.png";
import { modalAction, directionAction } from "../store/store-actions";
import { autoScroll, getDetailsModalContent } from "../lib/services";

const enteringMarker = (fsq_id) => {
  autoScroll(fsq_id);
  $(`#${fsq_id}`).addClass("border-primary");
};

const leavingMarker = (fsq_id) => {
  $(`#${fsq_id}`).removeClass("border-primary");
};

const GoogleMap = () => {
  const dispatch = useDispatch();

  const {
    curLocGeoCords,
    searchedLocGeoCords,
    spots,
    selectedSpot,
    origin,
    destination,
    travelMode,
    response,
  } = useSelector((state) => {
    return {
      curLocGeoCords: state.uiStates.curLocGeoCords.lat_lng,
      searchedLocGeoCords: state.uiStates.searchedLocGeoCords.lat_lng,
      spots: state.parking.spots,
      selectedSpot: state.modal.spot,
      origin: state.uiStates.curLocGeoCords.lat_lng,
      destination: state.directionProps.destination,
      travelMode: state.directionProps.travelMode,
      response: state.directionProps.response,
    };
  });

  const spotDetailsModal = (spot) => {
    dispatch(modalAction.setModalContent(getDetailsModalContent(spot)));
    dispatch(modalAction.setSpot(spot));
    dispatch(modalAction.setModalStatus(true));
  };

  const resetPosition = () => {
    if (selectedSpot) {
      return {
        lat: Number(selectedSpot.latitude),
        lng: Number(selectedSpot.longitude),
      };
    } else {
      let lat_lng = [];
      if (searchedLocGeoCords !== "") {
        lat_lng = searchedLocGeoCords.split(",");
        return { lat: Number(lat_lng[0]), lng: Number(lat_lng[1]) };
      } else if (curLocGeoCords !== "") {
        lat_lng = curLocGeoCords.split(",");
        return { lat: Number(lat_lng[0]), lng: Number(lat_lng[1]) };
      }
    }
  };

  const directionsCallback = (resp) => {
    if (resp !== null) {
      if (resp.status === "OK") {
        dispatch(directionAction.setResponse(resp));
        dispatch(directionAction.setDestination(""));
      }
    }
  };

  return (
    <div id="map" className="hidden md:block flex-1 relative">
      <Map latlng={resetPosition()} zoom={14}>
        {spots.length > 0 && (
          <MarkerClusterer
            imagePath="../assets/images/marker_cluster_imgs"
            gridSize={50}
            maxZoom={16}
          >
            {(clusterer) =>
              spots.map((spot) => (
                <Marker
                  key={spot.fsq_id}
                  position={{
                    lat: Number(spot.latitude),
                    lng: Number(spot.longitude),
                  }}
                  icon={marker}
                  clusterer={clusterer}
                  onClick={spotDetailsModal.bind(null, spot)}
                  onMouseOver={enteringMarker.bind(null, spot.fsq_id)}
                  onMouseOut={leavingMarker.bind(null, spot.fsq_id)}
                  title={spot.name}
                />
              ))
            }
          </MarkerClusterer>
        )}
        {origin !== "" && destination !== "" && (
          <DirectionsService
            options={{
              destination: destination,
              origin: origin,
              travelMode: travelMode,
            }}
            // required
            callback={directionsCallback}
          />
        )}
        {response !== null && (
          <DirectionsRenderer
            // required
            directions={response}
            panel={document.getElementById("directionSteps")}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleMap;
