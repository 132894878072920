import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import axios from "axios";
import "../App.css"
import { modalAction, directionAction, uiAction } from "../store/store-actions";
import StarRatings from "react-star-ratings";
import default_img from "../assets/images/default.png";
import { BASE_URL, DIRECTION, CONTACT, GOOGLE_MAP_URL } from "../config";
import {
  autoScroll,
  getDetailsModalContent,
  getContactModalContent,
  getCurrentUserIp,
} from "../lib/services";
import phone_icn from "../assets/images/phone_icn.png";
import info_icn from "../assets/images/info_icn.png";
import route_icn from "../assets/images/route_icn.png";

const Spot = (props) => {
  const { curLocGeoCords } = useSelector((state) => {
    return {
      curLocGeoCords: state.uiStates.curLocGeoCords.lat_lng,
    };
  });
  const dispatch = useDispatch();

  const placeHistoryInsertion = async (fsq_id, status) => {
    const user_ip = await getCurrentUserIp();
    axios.post(`${BASE_URL}/placeHistoryInsertion.json`, {
      params: { fsq_id, user_ip, status },
    });
  };

  const spotDetailsModal = (spot) => {
    dispatch(directionAction.setResponse(null));
    if ($("#directionSteps")) $("#directionSteps").remove();

    dispatch(modalAction.setModalContent(getDetailsModalContent(spot)));
    dispatch(modalAction.setSpot(spot));
    dispatch(modalAction.setModalStatus(true));
  };

  const spotContactModal = (spot) => {
    dispatch(directionAction.setResponse(null));
    placeHistoryInsertion(spot.fsq_id, CONTACT);
    if ($("#directionSteps")) $("#directionSteps").remove();

    let content = getContactModalContent(spot);
    dispatch(modalAction.setModalContent(content));
    dispatch(modalAction.setModalStatus(true));
  };

  const setDestiantionGeoCords = (props) => {
    placeHistoryInsertion(props.fsq_id, DIRECTION);
    if ($("#directionSteps")) $("#directionSteps").remove();

    $("<div id='directionSteps' class='bg-white mb-1 mr-2'></div>").appendTo(
      "#" + props.fsq_id
    );
    dispatch(directionAction.setDestination(`${props.lat},${props.lng}`));
    autoScroll(props.fsq_id);
  };

  const setCurAndDestinationGeoCords = (props) => {
    if (curLocGeoCords === null || curLocGeoCords === "") {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(
          uiAction.setCurLocGeoCords(
            `${position.coords.latitude},${position.coords.longitude}`
          )
        );
        setDestiantionGeoCords(props);
      });
    } else {
      setDestiantionGeoCords(props);
    }

    if ($(document).width() <= 767) {
      window.open(`${GOOGLE_MAP_URL}${props.lat},${props.lng}`);
    }
  };

  return (
    <div
      id={props.spot.fsq_id}
      className="bg-white mb-4 mr-2 rounded px-1 py-1 hover:bg-gray-lighter border-4 border-white hover:border-primary cursor-pointer h-9rem"
    >
      <div className="flex md:flex-row md:items-center p-1">
        <div className="flex-shrink-0 p-1">
          {props.spot.place_photos.length > 0 && (
            <img
              src={`${props.spot.place_photos[0].prefix}original${props.spot.place_photos[0].suffix}`}
              alt="ParkingSpots"
              style={{ width: "110px", height: "110px" }}
            />
          )}
          {props.spot.place_photos.length === 0 && (
            <img
              src={default_img}
              alt="ParkingSpots"
              style={{ width: "110px", height: "110px" }}
            />
          )}
        </div>
        <div className="flex-1 px-2">
          <div className="flex -mx-1">
            <h4 className="text-muted font-bold block">
              <span className="text clamp-1">{props.spot.name}</span>
            </h4>
          </div>
          <div className="flex flex-shrink justify-between">
            <StarRatings
              rating={Number((Number(props.spot.rating) / 2).toFixed(1))}
              starRatedColor="#d9a527"
              numberOfStars={5}
              starDimension="15px"
              starSpacing="1px"
              name={`rating_${props.spot.fsq_id}`}
            />
            <h4 className="text-muted font-bold block logo-color">{props.spot.distance} mi</h4>
          </div>
          <br />
          <br />
          <div className="flex -mx-1 justify-end">
            <img
              className="hidden spot_action_icn mr-8"
              src={info_icn}
              width={15}
              height={15}
              alt="i"
              onClick={spotDetailsModal.bind(null, props.spot)}
            />
            <p
              className="btn spot_action_btn mr-2"
              onClick={spotDetailsModal.bind(null, props.spot)}
            >
              details
            </p>
            {props.spot.tel !== null && (
              <>
                <img
                  className="hidden spot_action_icn mr-8"
                  src={phone_icn}
                  width={15}
                  height={15}
                  alt="pn"
                  onClick={spotContactModal.bind(null, props.spot)}
                />
                <p
                  className="btn spot_action_btn mr-2"
                  onClick={spotContactModal.bind(null, props.spot)}
                >
                  Book Now
                </p>
              </>
            )}
            <img
              className="hidden spot_action_icn"
              src={route_icn}
              width={15}
              height={15}
              alt="rt"
              onClick={setCurAndDestinationGeoCords.bind(null, {
                lat: props.spot.latitude,
                lng: props.spot.longitude,
                fsq_id: props.spot.fsq_id,
              })}
            />
            <p
              className="btn spot_action_btn"
              onClick={setCurAndDestinationGeoCords.bind(null, {
                lat: props.spot.latitude,
                lng: props.spot.longitude,
                fsq_id: props.spot.fsq_id,
              })}
            >
              Route
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spot;
