import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import useOnclickOutside from "react-cool-onclickoutside";
import logo from "../assets/images/logo.png";
import Loader from "../components/Loader";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faBars } from "@fortawesome/free-solid-svg-icons";

const toggleNavMobile = () => {
  $("#nav-mobile").toggleClass("hidden");
  if (!$("#submenu-1").hasClass("hidden")) toggleSubMenu();
};

const toggleSubMenu = () => {
  $("#submenu-1").toggleClass("hidden");
};

const toggleNavAndSubMenu = () => {
  toggleNavMobile();
  toggleSubMenu();
};

const Header = () => {
  const { loaderStatus } = useSelector((state) => {
    return {
      loaderStatus: state.uiStates.isLoading,
    };
  });

  const ref = useOnclickOutside(() => {
    if (!$("#nav-mobile").hasClass("hidden")) toggleNavMobile();
    if (!$("#submenu-1").toggleClass("hidden")) toggleSubMenu();
  });

  return (
    <header
      className="bg-white shadow relative z-50 sticky top-0"
      role="banner"
    >
      {loaderStatus && <Loader />}
      <div className="px-6 py-3 flex flex-col lg:flex-row lg:justify-between lg:items-center">
        <div className="flex justify-between flex-1">
          <Link to="/home">
            <img className="h-8" src={logo} alt="ParkingSpots" />
          </Link>
          <button
            className="inline-block border-2 border-gray-light w-8 h-8 lg:hidden"
            onClick={toggleNavMobile}
            ref={ref}
          >
            <FontAwesomeIcon icon={faBars} />
            <span className="fa fa-bars"></span>
          </button>
        </div>
        <nav
          id="nav-mobile"
          className="hidden lg:hidden -mx-6 -mb-3 mt-3 bg-secondary arrow-right"
          ref={ref}
        >
          <ul className="flex flex-col uppercase font-medium text-sm">
            <li
              className="border-t border-gray-light first:border-0"
              onClick={toggleNavMobile}
            >
              <Link to="/home" className="block px-6 py-2 text-white">
                Find Spots
              </Link>
            </li>
            <li className="border-t border-gray-light first:border-0">
              <p
                className="flex justify-between items-center px-6 py-2 text-white"
                onClick={toggleSubMenu}
              >
                Parking Spots
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="lg"
                  className="text-xs ml-2"
                />
              </p>
              <ul id="submenu-1" className="hidden">
                <li onClick={toggleNavAndSubMenu}>
                  <Link
                    to="/whoAndWhatWeAre"
                    className="block px-6 py-2 text-white"
                  >
                    Who &amp; What We Are
                  </Link>
                </li>
                <li onClick={toggleNavAndSubMenu}>
                  <Link
                    to="/worldOfParking"
                    className="block px-6 py-2 text-white"
                  >
                    The World of Parking
                  </Link>
                </li>
                <li onClick={toggleNavAndSubMenu}>
                  <Link
                    to="/environment"
                    className="block px-6 py-2 text-white"
                  >
                    Environment
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className="border-t border-gray-light first:border-0"
              onClick={toggleNavMobile}
            >
              <Link to="/contactUs" className="block px-6 py-2 text-white">
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
        <nav id="nav" className="hidden flex lg:flex lg:items-center">
          <ul className="flex uppercase text-xs font-medium">
            <li>
              <Link
                to="/home"
                className="px-3 py-1 text-gray-dark hover:text-primary"
              >
                Find Spots
              </Link>
            </li>
            <li className="group relative">
              <span className="px-3 py-1 text-gray-dark hover:text-primary">
                Parking Spots
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="lg"
                  className="text-xs text-muted ml-2"
                />
              </span>
              <ul className="hidden group-hover:block absolute bg-white px min-w-180px shadow-2xl border border-gray-lighter z-50">
                <li>
                  <Link
                    to="/whoAndWhatWeAre"
                    className="block px-3 py-2  hover:bg-primary hover:text-white"
                  >
                    Who &amp; What We Are
                  </Link>
                </li>
                <li>
                  <Link
                    to="/worldOfParking"
                    className="block px-3 py-2  hover:bg-primary hover:text-white"
                  >
                    The World of Parking
                  </Link>
                </li>
                <li>
                  <Link
                    to="/environment"
                    className="block px-3 py-2  hover:bg-primary hover:text-white"
                  >
                    Environment
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/contactUs"
                className="px-3 py-1 text-gray-dark hover:text-primary"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
