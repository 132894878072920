import { Link } from "react-router-dom";

const SubHeader = (props) => {
    return (
        <header id="page-header" className="border-b-2 border-gray-lighter">
          <div className="container mx-auto px-6 py-4">
            <ul className="flex text-xs uppercase -m-1">
              <li className="p-1">
                <Link to="/home" className="text-primary">
                  HOME
                </Link>
              </li>
              <li className="p-1 text-gray-light">/</li>
              <li className="p-1">
                <span>{props.title}</span>
              </li>
            </ul>
            <div className="flex flex-wrap flex-col md:flex-row md:items-center md:justify-between -m-2 py-3">
              <div className="p-2 flex-1">
                <h1 className="text-3xl text-primary">{props.title}</h1>
              </div>
            </div>
          </div>
        </header>
    );
};

export default SubHeader;