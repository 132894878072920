import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { modalAction } from "../store/store-actions";
import "reactjs-popup/dist/index.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal = (props) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(modalAction.setModalContent(null));
    dispatch(modalAction.setModalStatus(false));
  };

  return (
    <Popup
      open={props.modalStatus}
      position="center"
      closeOnDocumentClick={false}
      modal
    >
      <div>
        <header>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeModal}
            className="float-right cursor-pointer ml-auto opacity-50 hover:opacity-100"
          />
          <br />
        </header>
        <div className="modal-body">{props.content}</div>
        <footer />
      </div>
    </Popup>
  );
};

export default Modal;
