import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  destination: "",
  travelMode: "DRIVING",
  response: null,
};

const directionSlice = createSlice({
  name: "direction",
  initialState,
  reducers: {
    setDestination(state, action) {
      state.destination = action.payload;
    },
    setResponse(state, action) {
      state.response = action.payload;
    },
  },
});

export const directionAction = directionSlice.actions;
export default directionSlice.reducer;