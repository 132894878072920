import { useDispatch } from "react-redux";
import axios from "axios";
import { uiAction } from "../store/ui-states";
import { BASE_URL } from "../config";

//To make GET request
export const useGetHttp = () => {
  const sendGetRequest = (params, applyDataFunc) => {
    axios
      .get(`${BASE_URL}/${params.url}`)
      .then((resp) => {
        if (resp.status === 200) applyDataFunc(resp.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return sendGetRequest;
};

//To make GET/POST request
export const useHttp = () => {
  const dispatch = useDispatch();

  const sendRequest = (params, applyDataFunc) => {
    dispatch(uiAction.setLoaderStatus(true));

    axios({
      url: params.url ? params.url : "",
      method: params.method ? params.method : "GET",
      baseURL: `${BASE_URL}/`,
      data: params.data ? params.data : null,
    })
      .then((resp) => {
        if (resp.status === 200) applyDataFunc(resp.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .then(() => {
        dispatch(uiAction.setLoaderStatus(false));
      });
  };

  return sendRequest;
};
