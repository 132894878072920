import $ from "jquery";
import image1 from "../assets/images/banner-1.jpg";
import image2 from "../assets/images/banner-2.jpg";
import image3 from "../assets/images/banner-3.jpg";
import image4 from "../assets/images/banner-4.jpg";
import image5 from "../assets/images/banner-5.jpg";

const ImageSlider = () => {
  $(function () {
    var slider = $("#slider");
    var background = $("#slider-background");
    let backgrounds = [image2, image3, image4, image5, image1];

    if (0 === slider.length || 0 === background.length) {
      return;
    }

    if ("undefined" == typeof backgrounds) {
      console.warn("Warning: No slider backgrounds defined.");
      return;
    }

    setInterval(function () {
      var bg = backgrounds.shift();
      var copy = background.clone().insertAfter(background);
      background.css({
        backgroundImage: "url(".concat(bg, ")"),
      });
      backgrounds.push(bg);
      copy.fadeOut("fast", function () {
        return copy.remove();
      });
    }, 6000);
  });

  return (
    <div
      id="slider-background"
      className="bg-white bg-cover bg-center absolute top-0 right-0 bottom-0 left-0 z-10 pointer-events-none"
      style={{ backgroundImage: `url(${image1})` }}
    />
  );
};

export default ImageSlider;
