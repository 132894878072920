import { Fragment } from "react";
import Header from "../layout/Header";
import SubHeader from "../layout/SubHeader";
import Footer from "../layout/Footer";

const WorldOfParking = () => {
  return (
    <Fragment>
      <Header />
      <div id="content" className="flex flex-col flex-1">
        <SubHeader title="The World of Parking" />
        <main id="main">
          <div className="container mx-auto px-6 py-20">
            <div>
              As consumers and entrepreneurs continue to see the nascent
              potential of the business of parking; more and more services are
              springing up to address the matter. Whether you are looking for
              hourly, daily, weekly or monthly alternatives, the plethora of
              space available can sometimes be complicated to find, let alone
              reserve and pay for it. That is not so much the case today as it
              was before the advent of the sophisticated technology available
              today.
              <br />
              <br />
            </div>
            <div>
              Today’s technologies have made finding parking so much simpler and
              virtually hassle free. And that will only get simpler and more
              efficient in the immediate future as new technologies are
              introduced and perfected. One of the great motivators for new and
              innovative parking services is the staggering size of the
              marketplace. Billions of dollars are spent annually paying for
              parking. It is estimated there could be as many as two billion
              commercial parking spaces in North America.
              <br />
              <br />
            </div>
            <div>
              <strong>
                What’s Happening in the World of Parking Today?
                <br />
              </strong>
              <br />
            </div>
            <ul>
              <li>
                Innovative parking solutions have captured the attention of
                government at all levels and the automotive industry in
                particular.
              </li>
              <li>
                Applications and services such as{" "}
                <a href="http://www.parkingspots.com/">www.parkingspots.com</a>{" "}
                are becoming more refined and user-friendly as a method for
                drivers to locate available parking spaces.
              </li>
              <li>
                Parking applications are starting to be embedded in automobile
                navigation systems.
              </li>
              <li>
                Mobile applications are quickly becoming the norm for
                interaction with customers.
              </li>
              <li>
                Parking will be a significant element in terms of how we manage
                transportation in the future.
              </li>
              <li>
                Big business is waking up to the opportunity to provide parking
                service to drivers.
              </li>
              <li>
                The environmental impact of drivers seeking parking spots is
                significant in terms of carbon dioxide produced and fuel
                needlessly consumed.
              </li>
            </ul>
            <br />
            <div>
              <strong>
                What’s Happening in the World of Tomorrow?
                <br />
              </strong>
              <br />
            </div>
            <ul>
              <li>
                Intelligent parking systems are poised to make parking much
                easier.
              </li>
              <li>
                Sensors embedded in the pavement or parking meters with optical
                detectors will indicate that a car has pulled into the space,
                the device sends out a radio-frequency signal to a data
                collector. From there, information about open spots can be sent
                to parking administrators, electronic billboards or drivers’
                smartphones.
              </li>
              <li>
                Stacked parking is already here but expect a lot more as
                developers around the world scramble to find adequate amounts of
                land to accommodate parking requirements.
              </li>
              <li>
                Automated parking systems (APS) such as the puzzler parking
                system (
                <a href="http://www.youtube.com/watch?v=hIeceaLrQDk">
                  http://www.youtube.com/watch?v=hIeceaLrQDk
                </a>
                ) and the rotary parking system (
                <a href="http://www.youtube.com/watch?v=XC48qZ2TIZM">
                  http://www.youtube.com/watch?v=XC48qZ2TIZM
                </a>
                ) are being introduced.
              </li>
              <li>
                Real-time information systems will combine traffic information
                and GPS technology to get drivers to available parking spots in
                the least amount of time. This is also referred to as a ‘parking
                patch’.
              </li>
              <li>
                Radio frequency identification tags (RFID) are being developed
                for areas such as parking permits so parking wardens can check
                validity or working in conjunction with smart phone apps to give
                drivers real time information about available parking spaces.
              </li>
            </ul>
          </div>
        </main>
      </div>
      <Footer />
    </Fragment>
  );
};

export default WorldOfParking;
