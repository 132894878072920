import { Fragment } from "react";
import Spot from "../components/Spot";
import { useSelector } from "react-redux";

const SpotsList = () => {
  let spots = useSelector((state) => state.parking.spots);
  if (spots.length > 0) {
    spots = spots.slice().sort((a, b) => (Number(a.distance) > Number(b.distance) ? 1 : -1));
  }

  return (
    <Fragment>
      {spots.map((spot) => (
        <Spot key={spot.fsq_id} spot={spot} />
      ))}
    </Fragment>
  );
};

export default SpotsList;
