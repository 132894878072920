import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import Geocode from "react-geocode";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  spotsAction,
  uiAction,
  modalAction,
  directionAction,
} from "../store/store-actions";
import { GOOGLE_API_KEY } from "../config";
import { useHttp } from "../hooks/use-http";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faLocationDot } from "@fortawesome/free-solid-svg-icons";

const AutoComplete = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sendRequest = useHttp();

  const {
    curLocGeoCords,
    searchedLocGeoCords,
    searchText,
    postCode,
    postCodeSearched,
  } = useSelector((state) => {
    return {
      curLocGeoCords: state.uiStates.curLocGeoCords.lat_lng,
      searchedLocGeoCords: state.uiStates.searchedLocGeoCords.lat_lng,
      searchText: state.uiStates.searchText,
      postCode: state.uiStates.postCode,
      postCodeSearched: state.uiStates.postCodeSearched,
    };
  });

  const setPostCode = (zipCode, fullAddress) => {
    if (zipCode) {
      const exist = fullAddress.includes(zipCode);
      if (exist) {
        dispatch(uiAction.setPostCodeSearched(exist));
      }
    }
    dispatch(uiAction.setPostCode(zipCode));
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      language: "en",
      componentRestrictions: {
        country: ["us"],
      },
    },
    debounce: 300,
  });

  useEffect(() => {
    if (searchText !== "") setValue(searchText);
  }, [searchText, setValue]);

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    if (e.target.value === "") {
      dispatch(uiAction.setSearchedLocGeoCords(""));
      dispatch(uiAction.setSearchText(""));
    }
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      dispatch(uiAction.setPostCodeSearched(false));
      dispatch(uiAction.setPostCode(""));

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        const zipCode = getZipCode(results[0], false);
        setPostCode(zipCode, description);
        dispatch(uiAction.setSearchedLocGeoCords(`${lat},${lng}`));
        dispatch(uiAction.setSearchText(description));
        dispatch(spotsAction.setSpots([]));
        dispatch(modalAction.setModalContent(null));
        dispatch(modalAction.setSpot(null));
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="p-2 leading-none"
        >
          {`${main_text}, ${secondary_text}`}
        </li>
      );
    });

  const loadSpots = (data) => {
    dispatch(spotsAction.setSpots(data.spots));
    dispatch(uiAction.setMoreResultsCount(Number(data.pageCount)));
    history.push("/parkingSpots");
  };

  const getParkingSpots = (event) => {
    event.preventDefault();
    dispatch(directionAction.setResponse(null));
    if (searchedLocGeoCords === "") {
      alert("Please select a location from the dropdown and try again.");
      return;
    }

    sendRequest(
      {
        url: "getParkingSpots.json",
        method: "POST",
        data: {
          current_lat_lng: curLocGeoCords,
          searched_lat_lng: searchedLocGeoCords,
          searchText,
          postCode,
          postCodeSearched,
        },
      },
      loadSpots
    );
  };

  const setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch(uiAction.setLoaderStatus(true));
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      Geocode.setApiKey(GOOGLE_API_KEY);
      Geocode.setLanguage("en");
      Geocode.setRegion("us");
      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setValue(address, false);
          dispatch(uiAction.setSearchText(address));
          dispatch(uiAction.setLoaderStatus(false));
        },
        (error) => {
          console.error(error);
          dispatch(uiAction.setLoaderStatus(false));
        }
      );

      dispatch(uiAction.setCurLocGeoCords(`${lat},${lng}`));
      dispatch(uiAction.setSearchedLocGeoCords(`${lat},${lng}`));
      dispatch(spotsAction.setSpots([]));
      dispatch(modalAction.setModalContent(null));
    });
  };

  return (
    <div ref={ref} className="auto-complete-styles">
      <div className="flex items-center justify-start border border-b-0 border-secondary">
        <FontAwesomeIcon
          icon={faLocationDot}
          className="cursor-pointer pl-2 logo-color"
          onClick={setCurrentLocation}
          size="lg"
        />
        <input
          id="search-input"
          value={value}
          onChange={handleInput}
          placeholder="Find a spot"
          autoComplete="off"
          style={{
            color: "black",
            outline: "none",
            width: "80%",
            height: "40px",
            paddingLeft: "10px",
          }}
        />
        <button className="btn-icon search-btn" onClick={getParkingSpots}>
          <FontAwesomeIcon icon={faSearch} size="lg" />
        </button>
      </div>
      {status === "OK" && value !== searchText && (
        <div
          className="bg-white suggestion-list border border-secondary"
          style={{ color: "black", position: "relative" }}
        >
          <ul>{renderSuggestions()}</ul>
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
