import "../App.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.png";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer
      id="footer"
      role="contentinfo"
      className="border-t-2 border-gray-lighter"
    >
      <div className="bg-white">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center md:items-center md:justify-start px-3 py-6">
            <div className="w-full md:w-1/2 p-3 text-sm">
              <Link to="/home">
                <img className="h-8 mb-2" src={logo} alt="Laravel" />
              </Link>
              ParkingSpots is an aggregator platform that draws from numerous
              major parking spot providers so that you have access to a single,
              comprehensive resource for finding parking. We offer commercial
              and residential parking results in all of the major cities across
              North America.
              {/* <a href="./abc.html">Terms of Use</a> and{" "}
              <a href="./abc.html">Privacy Policy</a>. */}
            </div>
            <div className="w-full md:w-1/2 p-3 flex flex-col">
              <div className="inline-flex -m-1 md:self-end">
                <a className="btn-icon-round m-1" href="./abc.html">
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={faFacebookF}
                    transform="right-1# down-7#"
                  />
                </a>
                <a className="btn-icon-round m-1" href="./abc.html">
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={faTwitter}
                    transform="right-1# down-7#"
                  />
                </a>
                <a className="btn-icon-round m-1" href="./abc.html">
                  <FontAwesomeIcon
                    className="text-xs"
                    icon={faYoutube}
                    transform="right-1# down-7#"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-secondary text-white text-xs">
        <div className="container mx-auto px-6 py-4 text-center">
          <span>Copyright © 2023 Parking Spots. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
